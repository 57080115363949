import React from 'react';

const Footer = () => {
  return (
    <footer className="flex flex-col justify-center p-6 md:flex-row md:justify-between pb-24 standalone:hidden">
      <div className=" flex flex-col items-center md:flex-row">
        <span className="text-gray-700 text-sm text-center">© 2020 CVNNABIS</span>
        <span className="text-gray-700 text-sm md:ml-2">All rights reserved.</span>
      </div>
      <div className="flex flex-col items-center justify-center order-first mb-2 md:flex-row md:order-none md:mb-0">
        <a href="https://instagram.com/" target="_blank" className="cursor-pointer text-gray-700 text-sm mx-4 my-1 md:my-0">
          Cannabis Dictionary
        </a>
        <a
          href="https://instagram.com/cvnnabis_com"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer text-gray-700 text-sm mx-4 my-1 md:my-0"
        >
          Instagram
        </a>
      </div>
    </footer>
  );
};

export default Footer;
