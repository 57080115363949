import propTypes from 'prop-types';
import React, { useRef } from 'react';

import Loader from '../Loader';

const Button = ({ text, onClick, loading, forwardRef, className, invert, tag: Tag = 'button', ...props }) => {
  const ref = useRef();

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    ref.current.blur();
  };
  return (
    <>
      {!loading && (
        <Tag
          ref={(inst) => {
            ref.current = inst;
            if (forwardRef) forwardRef.current = inst;
          }}
          onClick={handleClick}
          className={`${
            invert ? 'text-white bg-black' : 'text-black bg-white border border-black hover:bg-gray-100 hover:text-black'
          } flex items-center justify-center w-full px-4 py-2 bg-transparent font-semibold text-sm tracking-wide uppercase rounded-sm h-12 hover:bg-smooth-black active:translate-y-1 transform ${className}`}
          {...props}
        >
          {text}
        </Tag>
      )}
      {loading && <Loader />}
    </>
  );
};

Button.propTypes = {
  text: propTypes.string.isRequired,
  onClick: propTypes.func,
  loading: propTypes.bool,
};

Button.defaultProps = {
  loading: false,
};

export default Button;
