import React from 'react';
import { Link } from 'gatsby';
import propTypes from 'prop-types';
import BagIcon, { BagIconFilled } from '../../assets/svg/BagIcon';

const HeaderLink = ({ children, path }) => {
  return (
    <Link
      to={path}
      className="cursor-pointer flex flex-grow items-center justify-center p-2 text-gray-700 hover:text-black"
    >
      {children}
    </Link>
  );
};

HeaderLink.propTypes = {
  children: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
};

const MobMenu = () => {
  return (
    <div className="w-full flex sm:hidden pt-safe-bottom standalone:h-24">
	    <div className="bg-gray-100 fixed bottom-0 pb-6 w-full border-t border-gray-200 flex">
        <HeaderLink path="/">
            <div className="text-center">
                <span className="block h-8 text-3xl leading-8 ml-1">
                  <BagIcon />
                </span>
                <span className="block text-xs leading-none">Shop</span>
            </div>
        </HeaderLink>
        <HeaderLink path="/new">
            <div className="text-center">
                <span className="block h-8 text-3xl leading-8 ml-1">
                  <BagIcon />
                </span>
                <span className="block text-xs leading-none">Latest</span>
            </div>
        </HeaderLink>
        <HeaderLink path="/on-sale">
            <div className="text-center">
                <span className="block h-8 text-3xl leading-8 ml-2">
                  <BagIcon />
                </span>
                <span className="block text-xs leading-none">Promos</span>
            </div>
        </HeaderLink>
        <HeaderLink path="/blog">
            <div className="text-center">
                <span className="block h-8 text-3xl leading-8 ml-1">
                  <BagIcon />
                </span>
                <span className="block text-xs leading-none">News</span>
            </div>
        </HeaderLink>
      </div>
    </div>
  );
};

export default MobMenu;
