import classNames from 'classnames';
import { navigate } from 'gatsby';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';

import ArrowLeft from '../../assets/svg/ArrowLeft';
import { useBag } from '../../context/BagContext';
import { useBagController, useBagDisplay } from '../../context/BagDisplayContext';
import { currencyMask } from '../../services/maskService';
import BagItem from '../BagItem/BagItem';
import Button from '../Button';
import Separator from '../Separator';

import './styles.css';

const Overlay = ({ onClick, visible }) => {
  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      onClick();
    }
  };

  return (
    <div
      onKeyDown={handleKeyPress}
      tabIndex={0}
      role="button"
      aria-label="overlay"
      className={classNames('fixed z-20 inset-0 bg-black transition-delay', {
        'block opacity-70': visible,
        'hidden opacity-0': !visible,
      })}
      onClick={onClick}
    ></div>
  );
};

Overlay.propTypes = {
  visible: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
};

const Bag = () => {
  const open = useBagDisplay();
  const { closeBag } = useBagController();
  const { bag, bagSize, totalValue } = useBag();

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [open]);

  const goToHomePage = () => {
    closeBag();
    navigate('/');
  };

  return (
    <>
      <div
        className={classNames(
          'transform fixed mx-auto bottom-0 flex flex-col h-3/4 z-30 min-h-0 max-h-80 w-full rounded-t-lg z-20 bg-white px-4 py-6 transition-delay',
          { 'translate-y-0': open, 'translate-y-full': !open }
        )}
      >
        <div className="w-full flex flex-row justify-between pb-2">
          <button aria-label="Close bag" onClick={closeBag} className="h-6 w-6">
            <ArrowLeft />
          </button>

          <h4 className="text-black text-xl sm:text-2xl font-extrabold lg:text-2xl leading-none">Wishlist</h4>
            <span className="font-extrabold">{bagSize}</span>
        </div>
        <Separator />
        <div className="w-full md:max-w-2xl mx-auto md:items-start flex-grow overflow-y-scroll">
        {bag?.length === 0 && (
          <div className="w-full max-w-xl flex flex-col flex-grow items-center mx-auto mt-24">
            <span className="text-gray-800 text-md mb-6">
              It appears you haven't saved any products to your wishlist.
            </span>
            <Button className="mb-12" text="Start shopping" onClick={goToHomePage}></Button>
          </div>
        )}

        {bag?.length > 0 && (
          <>
            <div data-testid="products" className="flex flex-col flex-grow">
              {bag.map(product => (
                <BagItem key={product.id} product={product} />
              ))}
            </div>
            {/* <Button text="Need help?" invert /> */}
          </>
        )}
        </div>
      <div className="w-full md:max-w-2xl mx-auto md:items-start pt-4">
      {/* <div className="w-full md:max-w-2xl mx-auto bg-gradient-to-b from-transparent fixed -m-12 to-white h-12"></div> */}
        <div className="w-full flex flex-col bg-black p-4 border border-black rounded-md">
              <div className="w-full flex flex-row justify-between">
                <span className="text-gray-400 text-sm">Subtotal</span>
                <span className="text-gray-400 text-sm font-semibold">
                  {currencyMask(totalValue)}
                </span>
              </div>
              <div className="w-full flex flex-row justify-between">
                <span className="text-gray-400 text-sm">Shipping</span>
                <span className="text-gray-400 text-sm font-semibold">{currencyMask(0)}</span>
              </div>
              <div className="w-full border-gray-800 border my-4 rounded"></div>
              <div className="w-full flex flex-row justify-between">
                <span className="font-semibold text-white">Total</span>
                <span className="font-semibold text-white text-lg">{currencyMask(totalValue)}</span>
              </div>
            </div>
        </div>
      </div>
      <Overlay visible={open} onClick={() => closeBag(false)} />
    </>
  );
};

export default Bag;
