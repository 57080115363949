import { Link } from 'gatsby';
import propTypes from 'prop-types';
import React, { useState } from 'react';

import { BagIconWhiteFilled } from '../../assets/svg/BagIconWhite';
import MenuIcon from '../../assets/svg/MenuIcon';
import { useBag } from '../../context/BagContext';
import { useBagController } from '../../context/BagDisplayContext';

const HeaderLink = ({ children, path }) => {
  return (
    <Link
      to={path}
      className="cursor-pointer block p-4 font-semibold text-base leading-thight tracking-wide text-white opacity-50 hover:opacity-100 sm:p-0 sm:m-0 sm:border-none sm:mx-4"
    >
      {children}
    </Link>
  );
};

HeaderLink.propTypes = {
  children: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
};

const Header = () => {
  const { showBag } = useBagController();
  const [open, setOpen] = useState(false);
  const { bagSize } = useBag();

  return (
    <header className="fixed w-full z-10 flex flex-row items-baseline justify-between bg-black px-4 sm:px-12 pt-safe-top standalone:h-24">
      <div className="w-full flex flex-col items-start sm:items-baseline sm:justify-between sm:flex-row">
        <div className="flex sm:flex-col w-full md:w-auto lg:w-auto items-center sm:py-2">
{/*           <button
            aria-label="Menu"
            type="button"
            onClick={() => setOpen(!open)}
            className="cursor-pointer text-white h-6 w-6 sm:hidden focus:outline-none focus:shadow-outline"
          >
            <MenuIcon open={open} />
          </button> */}
          <Link to="/" className="flex w-full">
            <h1 className="mb-0 text-3xl text-white font-extrabold cannabis-logo mx-auto">CVNNABIS</h1>
          </Link>
        </div>

        <div
          className={`${
            open ? 'flex' : 'hidden'
          } w-full flex-col mt-4 sm:my-0 sm:w-auto sm:flex sm:flex-row sm:items-center`}
        >
          <HeaderLink path="/">Shop</HeaderLink>
          <HeaderLink path="/new">Latest</HeaderLink>
          <HeaderLink path="/on-sale">On Sale</HeaderLink>
          <HeaderLink path="/blog">News</HeaderLink>
          <button
            aria-label="Show Bag"
            onClick={showBag}
            type="button"
            className="relative cursor-pointer hidden text-white h-6 w-6 mr-4 mb-1 focus:outline-none focus:shadow-outline sm:block sm:ml-8"
          >
            <BagIconWhiteFilled />
            <div className="absolute right-0 top-0 -mr-3 -mt-2 bg-white rounded-full h-5 w-5 flex items-center justify-center">
              <span className="text-black font-semibold text-2xs">{bagSize}</span>
            </div>
          </button>
        </div>
      </div>

      <button
        aria-label="Show Bag"
        onClick={showBag}
        type="button"
        className="relative cursor-pointer text-black h-6 w-6 mr-4 mb-1 focus:outline-none focus:shadow-outline sm:hidden sm:ml-8"
      >
        <BagIconWhiteFilled />
        <div className="absolute right-0 top-0 -mr-3 -mt-2 bg-white rounded-full h-5 w-5 flex items-center justify-center">
          <span className="text-black font-semibold text-2xs">{bagSize}</span>
        </div>
      </button>
    </header>
  );
};

export default Header;
